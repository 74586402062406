import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CasacloudTabs from './components/CasacloudTabs';
import Navbar from './components/Navbar';
import './index.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<CasacloudTabs />} />
          {/* <Route path="/resume" element={<Resume />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;

