import React from 'react';

const Navbar = () => {
  const handleResumeClick = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    window.open('/Alok-Adhao-Resume.pdf', '_blank');
  };

  return (
    <nav className="bg-black p-6">
      <div className="container mx-auto flex justify-between items-center">
      {/* <img src='./public/casacloud_watermark.png' alt="Logo" className="h-8 w-8 mr-2" /> */}
        <div className="text-white text-4xl font-bold">CasaCloud.com</div>
        <div className="space-x-4">
          <a 
            href="/resume.docx" 
            onClick={handleResumeClick} 
            className="text-white bg-blue-600 px-3 py-2 rounded hover:bg-blue-700"
          >
            Resume
          </a>
          {/* <a href="#" className="text-white bg-blue-600 px-3 py-2 rounded hover:bg-blue-700">More Info</a> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

